import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  loadRecaptchaScript,
  unloadRecaptchaScript,
} from "../../helpers/Recapture";
import { config } from "../../config/config";
import { VerifyToken } from "../../api-requests/Get";
import Footer from "../../components/Footer";

const SignUp = () => {
  const formRef = useRef(null);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [generation, setGeneration] = useState("-1");
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState("");
  const siteKey = config.RECAPTURE_SITE_KEY;

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return decodeURIComponent(cookie[1]);
      }
    }
    return null;
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    if (window.grecaptcha) {
      setIsError(false);
      e.preventDefault();

      const token = await window.grecaptcha.execute(siteKey);
      const isValid = await VerifyToken(token);
      if (!isValid) {
        setErrors("Invalid capture.");
        return;
      }

      // const hasErrors = false;

      if (password !== passwordMatch) {
        setIsError(true);
        setErrors(`Provided passwords don't match`);
      } else if (generation === "-1") {
        setIsError(true);
        setErrors("Please select a generation");
      } else {
        const usernameRegex = /^[a-zA-Z0-9_]{3,40}$/;
        if (!usernameRegex.test(username)) {
          setIsError(true);
          setErrors(`Invalid username format`);
        } else {
          try {
            const response = await fetch("/api/signup", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email,
                password,
                display_name: username,
                generation: generation,
              }),
            });

            if (response.status === 201) {
              const buyval = getCookie("ral");
              if (buyval) {
                deleteCookie("ral");
                formRef.current.submit();
              } else {
                window.location.href = "/";
              }
            } else {
              const errorMessage = await response.json();
              if (errorMessage.error.errors[0].type === "unique violation") {
                setErrors(`That email is already in use.`);
              } else {
                setErrors("Unable to create account");
              }

              setIsError(true);
            }
          } catch (error) {
            setErrors("Error creating account:");
            setIsError(true);
          }
        }
      }
    } else {
      console.error("reCAPTCHA instance is not available.");
    }
  };

  useEffect(() => {
    loadRecaptchaScript(siteKey)
      .then(() => {
        console.log("reCAPTCHA script loaded successfully");
      })
      .catch((err) => {
        console.error(err.message);
      });

    return () => {
      unloadRecaptchaScript();
    };
  }, [siteKey]);

  return (
    <>
      <div className="flex flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 m-6 sm:m-auto">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img
              className="mx-auto h-10 w-auto"
              src="/img/logo.png"
              alt="10GOATS"
            />
          </Link>
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Create your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow rounded-md sm:rounded-lg sm:px-12">
            {isError && (
              <>
                <div className="border border-red p-4 bg-rose-500 text-black font-semibold text-sm mb-5 rounded-md">
                  {errors}
                </div>
              </>
            )}
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Username
                </label>
                <div className="mt-2">
                  <input
                    id="display_name"
                    name="display_name"
                    type="display_name"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Username"
                    maxLength={40}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    id="password"
                    value={password}
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Your Password"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    id="confirm_password"
                    value={passwordMatch}
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPasswordMatch(e.target.value)}
                    placeholder="Confirm Password"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Generation
                </label>
                <div className="mt-2">
                  <select
                    id="generation"
                    name="generation"
                    value={generation}
                    onChange={(e) => setGeneration(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="-1">Choose Your Generation</option>
                    <option value="1">Baby Boomers (1946–1964)</option>
                    <option value="2">Generation X (1965–1980)</option>
                    <option value="3">Millennials (1981–1996)</option>
                    <option value="4"> Generation Z (1997–2012)</option>
                    <option value="5">Generation Alpha (2013–Present)</option>
                  </select>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>

          <p className="mt-10 text-center text-sm text-white">
            Already have an account?{" "}
            <Link
              className="font-semibold text-sky-500 hover:text-gray-100"
              to="/login"
            >
              Login
            </Link>
          </p>
          {/* <p className="mt-5 text-center text-sm text-white">
            By signing up, you agree to the{" "}
            <Link
              to="/tos"
              className="font-semibold text-sky-500 hover:text-gray-100"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy"
              className="font-semibold text-sky-500 hover:text-gray-100"
            >
              Privacy Policy
            </Link>
            .
          </p> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignUp;
