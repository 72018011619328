// src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./css/index.css";
import ReactGA from "react-ga4";
import { I18nextProvider } from "react-i18next";
import i18n from "./helpers/i18n";

import Home from "./features/home/Home";
import { GetFromLocalStorage } from "./helpers/LocalStorage";
import { UserContext } from "./context/UserContext";
import Admin from "./features/admin/Index";
import SignUp from "./features/auth/Signup";
import Login from "./features/auth/Login";
import axios from "axios";
import { getBearerHeaders } from "./helpers/GetBearerHeaders";
import Logout from "./features/auth/Logout";
import RequestPassword from "./features/auth/RequestPassword";
import UsersList from "./features/lists/UsersList";
import Profile from "./features/profile/Index";
import Ranks from "./features/ranks/Ranks";
import ConfirmPassword from "./features/auth/ConfirmPassword";
import Lists from "./features/lists/Index";
import CreateList from "./features/lists/Create";
//import { GetAnonToken } from "./api-requests/Get";
//import Cookies from "js-cookie";

//dotenv.config();

export interface User {
  id: string | number;
  username: string;
  display_name: string;
  generation: string;
  bg_color: string;
  isloggedin: boolean;
  expires: number;
}

function App() {
  const [isUserChecked, setUserChecked] = useState<boolean>(false);
  const [user, setUser] = useState<User>({
    id: -1,
    username: "",
    display_name: "",
    generation: "",
    bg_color: "",
    isloggedin: false,
    expires: null,
  });

  useEffect(() => {
    // console.log("INITIALIZE ANALYTICS");
    const storageLang = GetFromLocalStorage("language");
    const navLang = navigator.language.substring(0, 2);

    i18n.changeLanguage(storageLang ? storageLang : navLang);
    const host = window.location.hostname;
    if (!host.includes("localhost") && !host.includes("10goats")) {
      //console.log("NOT OUR DOMAIN REDIRECT", host);
      window.location.href = "https://10goats.com";
    }
    ReactGA.initialize("G-L90QK0NH5D");
    /* GetAnonToken().then((token) => {
      //console.log("Anon Token", token.token);
      //Cookies.set("jwt", token.token);
    }); */
    axios
      .post("/api/isauthenticated", {}, getBearerHeaders())
      .then((response) => {
        setUserChecked(true);
        if (response.data.success) {
          //console.log("USER", response.data.items);
          setUser(response.data.items);
        }
      });
  }, []);

  if (!isUserChecked) {
    return <></>;
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/thor"
              element={user.isloggedin ? <Admin /> : <Navigate to="/login" />}
            />
            <Route path="/l/:listid" element={<UsersList />} />
            <Route path="/u/:userid" element={<Profile />} />
            <Route path="/ranks/:cat_id" element={<Ranks />} />
            <Route
              path="/lists"
              element={user.isloggedin ? <Lists /> : <Navigate to="/login" />}
            />
            <Route
              path="/lists/create/:category_id"
              element={
                user.isloggedin ? <CreateList /> : <Navigate to="/login" />
              }
            />
            <Route path="/forgot-password" element={<RequestPassword />} />
            <Route path="/reset-password" element={<RequestPassword />} />
            <Route path="/update-password" element={<ConfirmPassword />} />
            <Route
              path="/update-password/:code"
              element={<ConfirmPassword />}
            />
          </Routes>
        </Router>
      </I18nextProvider>
    </UserContext.Provider>
  );
}

export default App;
