import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import UsersHeader from "../../components/UsersHeader";
import {
  GetCategoryByName,
  GetListByIdUser,
  GetUserByName,
} from "../../api-requests/Get";
import GoatListItem from "../../components/GoatListItem";
import { Helmet } from "react-helmet-async";
import PageLoading from "../../components/PageLoading";
import { UserContext } from "../../context/UserContext";
import GoatListAddEdit from "../../components/CreateList";
import { Switch } from "@headlessui/react";
import Footer from "../../components/Footer";

const Profile = () => {
  const { userid } = useParams();
  const { user } = useContext(UserContext);
  const [profile, setProfile] = useState<any>(null);
  const [primaryList, setPrimaryList] = useState<any>(null);
  const [catDetails, setCatDetails] = useState<any>(null);
  const [pageMode, setPageMode] = useState<"view" | "edit">("view");
  //const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const asyncGetUser = async (userid: string) => {
      const cat = await GetCategoryByName("Rappers");
      const userProfile = await GetUserByName(userid);
      setCatDetails(cat);
      //console.log("Cat", cat);

      if (userProfile && cat.category) {
        const usersList = await GetListByIdUser(userProfile.id, cat.category);
        //console.log("User Profile", userProfile.id, user.id);
        setProfile(userProfile);
        console.log(usersList);
        setPrimaryList(usersList);
      }
    };

    asyncGetUser(userid);
  }, []);

  if (!profile) {
    return <PageLoading />;
  }
  return (
    <>
      <Helmet>
        <title>10 GOATs Profile of {`${profile?.display_name}`}</title>
        <meta
          property="og:title"
          content={`10 GOATs Profile Of ${profile?.display_name}`}
        />
        <meta
          property="og:description"
          content={`Curate your ultimate top 10 emcee lists at 10goats.com! `}
        />
        <meta
          property="og:image"
          content="https://10goats.com/ogimage.jpg?v=4"
        />
        <meta
          property="og:url"
          content={`https://10goats.com/u/${profile?.display_name}`}
        />
        <meta
          name="description"
          content={`Here's ${profile?.display_name}'s 10 GOATs, Curate your ultimate top 10 emcee lists at 10goats.com! `}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="10goats.com" />
        <meta
          property="twitter:url"
          content={`https://10goats.com/u/${profile?.display_name}`}
        />
        <meta
          name="twitter:title"
          content={`10 GOATs Profile Of ${profile?.display_name}`}
        />
        <meta
          name="twitter:description"
          content={`Here's ${profile?.display_name}'s 10 GOATs, Curate your ultimate top 10 emcee lists at 10goats.com! `}
        />
        <meta
          name="twitter:image"
          content="https://10goats.com/twittercard.jpg?v=4"
        />
      </Helmet>
      <Header />
      <UsersHeader profile={profile} />
      <div className="mx-auto max-w-5xl pb-6 mt-10 p-4 md:p-0">
        <div className="flex flex-row justify-between items-center mb-4">
          <h2 className=" text-xl font-bold uppercase">
            <a href={`/l/${primaryList[0].data.id}`} className="text-sky-500">
              10 GOAT {catDetails?.name}
            </a>
          </h2>

          {user.isloggedin && profile.id === user.id && (
            <div className="flex flex-row items-center">
              <span
                className="text-xs text-gray-400 uppercase cursor-pointer mr-2 font-bold"
                onClick={() =>
                  setPageMode(pageMode === "view" ? "edit" : "view")
                }
              >
                {pageMode === "edit" ? "View" : "Edit"} List
              </span>
              <Switch
                checked={pageMode === "view" ? false : true}
                onChange={() =>
                  setPageMode(pageMode === "view" ? "edit" : "view")
                }
                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-green-600"
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                />
              </Switch>
            </div>
          )}
        </div>
        {pageMode === "edit" && (
          <GoatListAddEdit
            categoryId={catDetails.category}
            mode="edit"
            goatData={primaryList[0].data}
          />
        )}
        {pageMode === "view" && (
          <>
            <p className="text-sm my-4">{primaryList[0].data.details}</p>
            <ul role="list" className="grid grid-cols-1 md:grid-cols-2 gap-3">
              {primaryList && primaryList.length ? (
                primaryList[0].data.UserListItems.map((item, index) => {
                  return (
                    <GoatListItem
                      key={index}
                      id={item.Item?.id}
                      category_label={item.Item?.name}
                      category_id={primaryList[0].data.categoryId}
                      image={item.Item?.imagePath}
                      index={index}
                      total_points={item.Item?.points}
                      average_rank={item.Item?.avg_rank}
                      number_of_lists={item.Item?.num_lists}
                    />
                  );
                })
              ) : (
                <div className="">No lists found</div>
              )}
            </ul>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Profile;
