import axios from "axios";

export const GetRanksByCategory = async (catid: string) => {
  const res = await axios.get(`/api/ranks/cat/${catid}`);
  return res.data;
};

export const GetCategory = async (catid: string) => {
  const res = await axios.get(`/api/categories/${catid}`);
  return res.data;
};

export const GetRandomUserList = async () => {
  const res = await axios.get(`/api/random-user-list`);
  return res.data;
};

export const GetUsersListById = async (listId: string) => {
  const res = await axios.get(`/api/list/${listId}`);
  return res.data;
};

export const GetListByIdUser = async (userid: string, category: string) => {
  const res = await axios.get(`/api/list/${category}/${userid}`);
  return res.data;
};

export const GetUserByName = async (name: string) => {
  const res = await axios.get(`/api/user/${name}`);
  return res.data;
};

export const GetCategoryByName = async (name: string) => {
  const res = await axios.get(`/api/category/${name}`);
  return res.data;
};

export const GetRanksAllByCat = async (cat_id: string) => {
  const res = await axios.get(`/api/ranks/all/${cat_id}`);
  return res.data;
};

export const VerifyToken = async (token: string) => {
  const res = await axios.post(`/api/verify-recaptcha/`, { token: token });
  return res.data;
};

export const GetAnonToken = async () => {
  const res = await axios.get(`/api/get-anonymous-token`);
  return res.data;
};

export const RapperRanks = async () => {
  const res = await axios.get(`/api/generate-ranks`);
  return res.data;
};

export const RanksByCatGen = async (catid: string, gen: number) => {
  const res = await axios.get(`/api/ranks/cat/${catid}/gen/${gen}`);
  return res.data;
};

export const GetAppStats = async (headers) => {
  const res = await axios.post(`/api/site-stats`, {}, headers);
  return res.data;
};

export const GetAllLists = async () => {
  const res = await axios.get(`/api/get-all-lists`);
  return res.data;
};

export const GetLatestUsers = async (headers) => {
  const res = await axios.post(`/api/get-latest-users`, {}, headers);
  return res.data;
};
