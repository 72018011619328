import React, { useContext, useEffect, useState } from "react";
import AdSense from "react-adsense";
//import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Styles from "../../helpers/Styles";

import {
  GetCategoryByName,
  GetListByIdUser,
  GetRandomUserList,
  GetRanksByCategory,
  RapperRanks,
} from "../../api-requests/Get";
import GoatListAddEdit from "../../components/CreateList";
import UsersList from "../../components/UsersList";
import { UserContext } from "../../context/UserContext";
import GoatListItem from "../../components/GoatListItem";
import PageLoading from "../../components/PageLoading";

const Home = () => {
  //const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [topRanks, setRanks] = useState<any>(null);
  const [randomUserList, setRandomUserList] = useState<any>(null);
  const [goatList, setGoatList] = useState<any>({});
  const [category_id, setCategory_id] = useState<any>(null);

  const asyncGetRanks = async () => {
    try {
      await RapperRanks();
    } catch (err) {
      console.log("testSecureResult", err);
    }

    const cat = await GetCategoryByName("Rappers");
    let catId;

    if (cat.success) {
      setCategory_id(cat.category);
      catId = cat.category;
    }

    if (user.isloggedin) {
      const myList = await GetListByIdUser(user.id, catId);

      if (myList[0] !== undefined) {
        setGoatList(myList[0].data);
      }
      const rank = await GetRanksByCategory(catId);
      setRanks(rank);
    }

    const usersList = await GetRandomUserList();
    setRandomUserList(usersList);
  };

  useEffect(() => {
    asyncGetRanks();
  }, [user]);

  if (!category_id) {
    return <PageLoading />;
  }

  return (
    <div>
      <Header />
      <div className="flex justify-center  min-h-screen">
        <div className={Styles.page_container}>
          <div className="flex justify-center my-4">
            <AdSense.Google
              client="ca-pub-993288253482590"
              slot="5582515344"
              style={{ display: "block" }}
              format="auto"
              responsive="true"
            />
          </div>
          <h2 className="text-3xl md:text-4xl font-bold uppercase mb-1">
            10 GOATS Spotlight
          </h2>
          <p className="text-sm">User Picks for Top 10 GOAT Emcees</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {randomUserList !== null &&
              randomUserList.map((list: any, index: number) => {
                return <UsersList key={index} listdata={list} />;
              })}
          </div>

          <div className="mt-20">
            <div className="flex justify-center">
              {user.isloggedin && Object.keys(goatList).length > 0 ? (
                <div className="w-full justify-center">
                  <h2 className="text-3xl md:text-4xl font-bold uppercase mb-4">
                    How does your list compare?
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="justify-center">
                      <h2 className="text-xl md:text-2xl font-extrabold uppercase mb-4">
                        10 GOATS Overall Standings
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        {topRanks !== null &&
                          topRanks.map((rank: any, index: number) => {
                            return (
                              <GoatListItem
                                key={index}
                                id={rank.id}
                                category_label={rank.category_label}
                                category_id={rank.categoryId}
                                image={rank.imagePath}
                                index={index}
                                total_points={rank.total_points}
                                average_rank={rank.average_rank}
                                number_of_lists={rank.number_of_lists}
                              />
                            );
                          })}
                      </div>
                    </div>
                    <div className="w-full">
                      <h2 className="text-xl md:text-2xl font-extrabold uppercase mb-4">
                        Your Top 10 Goat Emcees
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        {goatList !== null &&
                          goatList.UserListItems.map(
                            (rank: any, index: number) => {
                              return (
                                <GoatListItem
                                  key={index}
                                  id={rank.Item.id}
                                  category_label={rank.Item.name}
                                  category_id={rank.Item.categoryId}
                                  image={rank.Item.imagePath}
                                  index={index}
                                  total_points={rank.Item.points}
                                  average_rank={rank.Item.avg_rank}
                                  number_of_lists={rank.Item.num_lists}
                                />
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <h2 className="text-4xl font-bold uppercase">
                  Create Your Goat Emcee List{" "}
                </h2>
              )}
            </div>
            {user.isloggedin && Object.keys(goatList).length === 0 && (
              <GoatListAddEdit categoryId={category_id} mode="add" />
            )}
            {user.isloggedin && Object.keys(goatList).length > 0 && <></>}

            {!user.isloggedin && (
              <>
                <div className="flex justify-center">
                  <p className="mt-4">
                    <a
                      href="/login"
                      className="underline text-sky-500 hover:text-gray-100"
                    >
                      Login
                    </a>{" "}
                    or{" "}
                    <a
                      href="/signup"
                      className="underline text-sky-500 hover:text-gray-100"
                    >
                      sign up
                    </a>{" "}
                    to create your list, view the rankings and more.
                  </p>
                </div>
                <div className="blur-md">
                  {category_id !== null && (
                    <GoatListAddEdit categoryId={category_id} mode="add" />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
