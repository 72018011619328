import React, { useContext, useEffect, useState } from "react";
import GoatListItem from "../../components/GoatListItem";
import { GetRanksAllByCat, RanksByCatGen } from "../../api-requests/Get";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import PageLoading from "../../components/PageLoading";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import isTestUser from "../../helpers/isTestUser";
import { UserContext } from "../../context/UserContext";

const Ranks = () => {
  const { user } = useContext(UserContext);
  const { cat_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [listData, setListData] = useState(null);
  const [generation, setGeneration] = useState(0);

  const generationList = [
    { id: 0, name: "Overall Ranking" },
    { id: 1, name: "Baby Boomers" },
    { id: 2, name: "Generation X" },
    { id: 3, name: "Millennials" },
    { id: 4, name: "Generation Z" },
    { id: 5, name: "Generation Alpha" },
  ];

  const getRanks = async (gen) => {
    setIsLoading(true);
    if (gen === 0) {
      getAllRanks();
      return;
    } else {
      const rankedItems = await RanksByCatGen(cat_id, gen);
      setListData(rankedItems);
      //console.log("Ranked Items", rankedItems);
      setGeneration(gen);
    }
  };

  const getAllRanks = async () => {
    setIsLoading(true);
    const listResults = await GetRanksAllByCat(cat_id);
    //console.log("List Results", listResults);
    setListData(listResults);

    setGeneration(0);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [listData]);

  useEffect(() => {
    getAllRanks();
  }, []);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <>
      <Header />

      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-4 justify-center">
          {isTestUser(user) && (
            <>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <MenuButton className="group inline-flex justify-center text-sm font-medium text-gray-400 hover:text-gray-500">
                    Filter
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    {generationList.map((option) => (
                      <MenuItem key={option.id}>
                        <a
                          onClick={() => getRanks(option.id)}
                          className="block px-4 py-2 text-sm font-medium text-gray-900 data-[focus]:bg-gray-100"
                        >
                          {option.name}
                        </a>
                      </MenuItem>
                    ))}
                  </div>
                </MenuItems>
              </Menu>
              <span className="text-gray-400 text-sm uppercase ml-2">
                {generationList[generation].name}
              </span>
            </>
          )}
          <h1 className="text-2xl font-bold uppercase">Current Rankings </h1>
          <p className="text-sm">
            Ranking based on number of lists and position on users lists
          </p>
        </div>

        {listData && listData.length === 0 ? (
          <>No data found</>
        ) : (
          <ul role="list" className=" grid grid-cols-1 md:grid-cols-3 gap-3">
            {listData &&
              listData.map((item, index) => {
                return (
                  <GoatListItem
                    key={index}
                    id={item.id}
                    category_label={item.name}
                    category_id={item.categoryId}
                    image={item.imagePath}
                    index={index}
                    total_points={item.points}
                    average_rank={item.avg_rank}
                    number_of_lists={item.num_lists}
                    highlightFirst={false}
                    hash={location.hash}
                  />
                );
              })}
          </ul>
        )}
      </div>
    </>
  );
};

export default Ranks;
