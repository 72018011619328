import React, { useEffect, useState } from "react";

import { GetAppStats, GetLatestUsers } from "../../api-requests/Get";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";
import PageLoading from "../../components/PageLoading";
import Header from "../../components/Header";
import { generations } from "../../constants/constants";
import { formatDate } from "../../helpers/Dates";

const Admin = () => {
  const [stats, setStats] = useState<any>(null);
  const [people, setPeople] = useState<any>(null);

  const getStats = async () => {
    const appStats = await GetAppStats(getBearerHeaders());
    const latestUsers = await GetLatestUsers(getBearerHeaders());
    console.log("latestUsers", latestUsers.data);
    console.log("appStats", appStats.data);
    setStats(appStats.data);
    setPeople(latestUsers.data);
  };

  useEffect(() => {
    getStats();
  }, []);

  if (!stats) {
    return <PageLoading />;
  }

  return (
    <>
      <Header />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-6">
        {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
        <div className="mx-auto max-w-3xl">
          <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl">
              <div className="grid grid-cols-2 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-3">
                {stats.map((event, eventIdx) => (
                  <div
                    key={eventIdx}
                    className="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8"
                  >
                    <p className="text-sm font-medium leading-6 text-gray-400">
                      {event.label}
                    </p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                      <span className="text-4xl font-semibold tracking-tight text-white">
                        {isNaN(event.value) ? event.value : event.value - 10}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
              <div className="p-4">
                <h1 className="my-4 font-bold uppercase text-xl text-white">
                  Latest Users
                </h1>
                <ul
                  role="list"
                  className="grid grid-cols-1 sm:grid-cols-2 gap-3"
                >
                  {people.map((person) => (
                    <li
                      key={person.email}
                      className="flex gap-x-4 py-5 border border-t-0 border-r-0 border-l-0 border-b-1 border-slate-800 p-2"
                    >
                      <img
                        alt=""
                        src={person.imageUrl}
                        className="h-12 w-12 flex-none rounded-full bg-gray-50"
                      />
                      <div className="min-w-0">
                        <span className="block text-xs font-medium leading-6 text-white">
                          {generations[person.generation - 1]}
                        </span>
                        <p className="text-sm font-semibold leading-6 text-sky-500">
                          <a href={`/u/${person.display_name}`}>
                            {person.display_name}
                          </a>
                        </p>
                        <time className="text-xs font-semibold text-gray-500">
                          {formatDate(person.created)}
                        </time>
                        <p className="mt-1 truncate text-xs leading-5 text-white">
                          Lists: {person.listCount}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
