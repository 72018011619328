import React, { useEffect, useState } from "react";
import { generations } from "../constants/constants";
import ShareButtons from "./shareBtns/Index";

export interface UsersHeaderProps {
  profile: any;
  header_size?: string;
}

const UsersHeader = (props: UsersHeaderProps) => {
  const [headerHeight, setHeaderHeight] = useState("h-48");

  useEffect(() => {
    if (props.header_size === "sm") {
      setHeaderHeight("h-16");
    }
  }, []);

  return (
    <div className="mx-auto max-w-5xl bg-slate-900 pb-6 rounded-md">
      <div
        className={`h-32 w-full object-cover lg:${headerHeight} bg-${props.profile?.bg_color}-800 overflow-hidden relative z-0`}
      >
        <div className="absolute right-2 top-2 ">
          <ShareButtons
            text={`Explore the top 10 goat emcees curated by ${props.profile?.display_name} and create your own at 10goats.com #GOATs #Top10Emcees #10goats`}
            twitter_url={`${window.location}`}
            url={`${window.location}`}
          />
        </div>
      </div>
      <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 z-1 relative">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5 ">
          <div className="flex">
            <img
              alt=""
              src={
                props.profile?.avatar
                  ? props.profile?.avatar
                  : "img/profile/10goatshead.png"
              }
              className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
            />
          </div>

          <div className="mt-6 flex-row sm:flex sm:min-w-0 sm:flex-1 items-center sm:justify-end sm:space-x-6 sm:pb-1 ">
            <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
              <span className="text-xs font-bold uppercase text-gray-300">
                {generations[props.profile?.generation - 1]}
              </span>
              <h1 className="truncate text-2xl font-bold ">
                <a href={`/u/${props.profile?.display_name}`}>
                  {props.profile?.display_name}
                </a>
              </h1>
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0 items-center"></div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
          <h1 className="truncate text-2xl font-bold text-gray-900">
            {props.profile?.display_name}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default UsersHeader;
