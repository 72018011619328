import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";

const rootElement = document.getElementById("root");

const root = (ReactDOM as any).createRoot(rootElement);
root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
