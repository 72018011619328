import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ItemInput from "../components/ItemInput";
import { GetCategory, VerifyToken } from "../api-requests/Get";
import { UserContext } from "../context/UserContext";
import { CreateGoatList } from "../api-requests/Create";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import ReCAPTCHA from "react-google-recaptcha";
import { config } from "../config/config";
import {
  loadRecaptchaScript,
  unloadRecaptchaScript,
} from "../helpers/Recapture";
import { getBearerHeaders } from "../helpers/GetBearerHeaders";
export interface GoatListAddEditProps {
  categoryId: string;
  mode: "add" | "edit";
  goatData?: any;
}

declare const grecaptcha: any;

const GoatListAddEdit = (props: GoatListAddEditProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [category, setCategory] = useState<any>(null);
  const [description, setDescription] = useState<string>("");
  const [goats, setGoats] = useState(Array(10).fill(""));
  const [errors, setErrors] = useState<string>("");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const siteKey = config.RECAPTURE_SITE_KEY;
  //const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  //console.log(config.RECAPTURE_SITE_KEY);

  const handleInputChange = (index: number, value: string) => {
    setGoats((prevGoats) => {
      const newGoats = [...prevGoats];
      newGoats[index] = value;
      return newGoats;
    });
  };

  /* const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  }; */

  const handleSubmit = async () => {
    if (window.grecaptcha) {
      try {
        const token = await window.grecaptcha.execute(siteKey);
        const isValid = await VerifyToken(token);
        if (!isValid) {
          setErrors("Invalid capture.");
          return;
        }
        //console.log("reCAPTCHA Valid:", isValid);
        if (goats.some((value) => value.trim() === "")) {
          //console.log("Error:All fields must be filled.");
          setErrors("All fields must be filled.");
          return;
        }

        const uniqueValues = new Set(goats);
        if (uniqueValues.size !== goats.length) {
          console.log("Error:No duplicate entries allowed.", uniqueValues);
          setErrors("No duplicate entries allowed.");
          return;
        }

        //console.log("errors", errors);
        const data = {
          category_id: props.categoryId,
          user_id: user.id,
          items: goats,
          description: description,
          mode: props.mode,
          list_id: props.goatData
            ? props.goatData.UserListItems[0].userListId
            : null,
        };

        const createResult = await CreateGoatList(data, getBearerHeaders());
        //console.log(data);
        //const createResult = { success: false, list_id: "1234" };
        if (createResult.success && props.mode === "add") {
          navigate(`/l/${createResult.list_id}`);
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error submitting list:", error);
      }
    } else {
      //console.error("reCAPTCHA instance is not available.");
      setErrors("reCAPTCHA instance is not available.");
    }
  };

  useEffect(() => {
    if (props.goatData) {
      const oldGoats = props.goatData.UserListItems.map((goat) => {
        return goat.Item.name;
      });
      console.log("oldGoats", props.goatData.details);
      setGoats(oldGoats);
      setDescription(props.goatData.details);
    }
  }, [props.goatData]);

  useEffect(() => {
    if (errors !== "") {
      toast.error(errors, {
        theme: "colored",
        position: "top-center",
      });
      setErrors("");
    }
  }, [errors]);

  useEffect(() => {
    if (user.isloggedin) {
      loadRecaptchaScript(siteKey)
        .then(() => {
          //console.log("reCAPTCHA script loaded successfully");
        })
        .catch((err) => {
          console.error(err.message);
        });
    }

    return () => {
      unloadRecaptchaScript();
    };
  }, [siteKey]);

  useEffect(() => {
    const asyncGetCategory = async () => {
      const catdata = await GetCategory(props.categoryId);
      setCategory(catdata);
    };

    asyncGetCategory();
  }, []);

  return (
    <div>
      <p className="mb-4 text-lg font-bold uppercase">
        Create your top {category?.name} {t("list")}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {goats.map((goat, index) => (
          <div key={index} className="">
            <ItemInput
              key={`item_${index}`}
              label={`GOAT #${index + 1}`}
              id={`goat_${index}`}
              name={`goat_${index}`}
              type="text"
              placeholder={`Goat ${index + 1}`}
              value={goat}
              categoryId={props.categoryId}
              onChange={handleInputChange}
              index={index}
              onIndexFocus={setActiveIndex}
              activeIndex={activeIndex}
            />
          </div>
        ))}
      </div>
      <div className="col-span-full mt-4">
        <label
          htmlFor="about"
          className="block text-sm font-medium leading-6 text-white"
        >
          Write a few sentences about why your goats are your goats.
        </label>
        <div className="mt-2">
          <textarea
            id="about"
            name="about"
            rows={3}
            className="block w-full rounded-md border-0 py-2 px-4 text-white bg-slate-900 shadow-sm ring-1 ring-inset ring-slate-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value.trim())}
          />
        </div>
      </div>
      <div id="recaptcha-container"></div>
      <div className="flex justify-center mt-8">
        <button
          className="flex w-full md:w-1/4 justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sangria-600 uppercase"
          onClick={handleSubmit}
        >
          {props.mode === "add" ? "Create List" : "Update List"}
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default GoatListAddEdit;
