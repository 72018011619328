import axios from "axios";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Logout = () => {
  function deleteCookie(name) {
    document.cookie =
      name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  deleteCookie("jwt");
  deleteCookie("rftk");

  axios.post("/api/logout", {}, { withCredentials: true }).then(() => {
    window.location.href = "/";
  });

  return (
    <>
      <Header />
      <div className="main">
        <div className="pages-wrap">
          <span>Logging out...</span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Logout;
