const loadRecaptchaScript = (siteKey: string) => {
  return new Promise<void>((resolve, reject) => {
    const existingScript = document.getElementById("recaptcha-script");
    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.id = "recaptcha-script";
    script.onload = () => resolve();
    script.onerror = () => reject(new Error("Failed to load reCAPTCHA script"));
    document.body.appendChild(script);
  });
};

const unloadRecaptchaScript = () => {
  console.log("Unloading reCAPTCHA script");
  const script = document.getElementById("recaptcha-script");
  const elements = document.querySelectorAll(`.grecaptcha-badge`);
  if (script) {
    document.body.removeChild(script);
    elements.forEach((element) => element.remove());
  }
};

export { loadRecaptchaScript, unloadRecaptchaScript };
