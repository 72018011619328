import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
//import { Helmet } from "react-helmet-async";
import PageLoading from "../../components/PageLoading";
import { GetAllLists } from "../../api-requests/Get";
import CategoryItem from "../../components/lists/CategoryItem";
import Footer from "../../components/Footer";

const Lists = () => {
  const [listData, setListData] = useState(null);

  useEffect(() => {
    const asyncGet = async () => {
      try {
        const listResults = await GetAllLists();
        //console.log(listResults);
        setListData(listResults);
      } catch (e) {
        console.log(e);
      }
    };
    asyncGet();
  }, []);

  if (!listData) {
    return <PageLoading />;
  }

  return (
    <>
      <Header />

      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mb-2">
          <h1 className="my-4 font-bold uppercase text-xl">GOAT LISTS</h1>

          <div className="grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            {listData.map((list: any) => {
              return (
                <CategoryItem
                  key={list.id}
                  id={list.id}
                  name={list.name}
                  description={list.description}
                />
              );
            })}
            <div className="p-4 border-blue-charcoal-800 border">
              <h1 className="uppercase">MORE GOAT LISTS COMING SOON...</h1>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Lists;
