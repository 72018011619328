import axios from "axios";
import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

export interface ItemInputProps {
  label: string;
  id: string;
  name: string;
  type: string;
  index: number;
  placeholder: string;
  categoryId: string;
  value: string;
  onChange: (index: number, value: string) => void;
  onIndexFocus: (index: number) => void;
  activeIndex: number;
}

const ItemInput = (props: ItemInputProps) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  // const [activeSuggestionIndex] = useState(-1);

  /* const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    props.onChange(e);

    if (userInput.length < 2) {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    const filtered = suggestions.filter(
      (suggestion: any) =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setFilteredSuggestions(filtered);
    setActiveSuggestionIndex(-1);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion: string) => {
    props.onChange({
      target: { value: suggestion },
    } as React.ChangeEvent<HTMLInputElement>);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  }; */

  /* const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      if (activeSuggestionIndex > -1) {
        props.onChange({
          target: { value: filteredSuggestions[activeSuggestionIndex].name },
        } as React.ChangeEvent<HTMLInputElement>);
        setShowSuggestions(false);
      }
    } else if (e.keyCode === 38) {
      // Up arrow
      if (activeSuggestionIndex === 0) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (e.keyCode === 40) {
      // Down arrow
      if (activeSuggestionIndex + 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  }; */

  const doSearch = async () => {
    if (props.value.length < 2) {
      setFilteredSuggestions([]);
      return;
    }

    try {
      const response = await axios.get("/api/search", {
        params: {
          q: props.value,
          categoryId: props.categoryId,
        },
      });

      setFilteredSuggestions(response.data);
    } catch (error) {
      console.error("Failed to fetch suggestions:", error);
    }
  };

  const handleOnSearch = (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    props.onChange(props.index, string);
    //console.log(string, filteredSuggestions, results);
  };

  const handleOnHover = (_result) => {
    // the item hovered
    props.onIndexFocus(props.index);
    //console.log(result);
  };

  const handleOnClear = () => {
    props.onChange(props.index, "");
  };

  const handleOnSelect = (item) => {
    // the item selected
    props.onChange(props.index, item.name);
    //console.log("item selected", item.name);
  };

  const handleOnFocus = () => {
    props.onIndexFocus(props.index);
    //console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        <span
          style={{
            display: "block",
            textAlign: "left",
            backgroundColor: "#0f172a",
            color: "white",
          }}
        >
          {item.name}
        </span>
      </>
    );
  };

  useEffect(() => {
    doSearch();
  }, [props.value, props.categoryId]);

  return (
    <div className="relative">
      <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-slate-800 focus-within:ring-2 focus-within:ring-indigo-600 bg-slate-950">
        <label htmlFor="name" className="block text-xl font-medium">
          {props.label}
        </label>
        {/*  <input
          id={props.id}
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          className="block w-full border-0 text-xl py-2 px-3 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 mt-3 mb-2 bg-slate-900 text-white"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={props.value}
          autoComplete="off"
        /> */}
        <ReactSearchAutocomplete
          items={filteredSuggestions}
          onSearch={handleOnSearch}
          onHover={handleOnHover}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          onClear={handleOnClear}
          formatResult={formatResult}
          placeholder={props.placeholder}
          fuseOptions={{ minMatchCharLength: 2, keys: ["name"] }}
          resultStringKeyName="name"
          showNoResults={false}
          inputSearchString={props.value}
          className={`focus:ring-0 sm:text-sm sm:leading-6 mt-3 mb-2 bg-slate-900 text-white ${
            props.activeIndex === props.index ? "z-50" : ""
          }`}
          styling={{
            height: "44px",
            border: "1px solid #2a3a59",
            borderRadius: "5px",
            backgroundColor: "#0f172a",
            hoverBackgroundColor: "#1b2846",
            //   boxShadow: "rgba(1, 1, 1, 0.28) 0px 1px 6px 0px",
            color: "#fff",
            //   fontSize: "16px",
            //   fontFamily: "Arial",
            //   iconColor: "grey",
            //   lineColor: "rgb(232, 234, 237)",
            placeholderColor: "#9ca3af",
            //   clearIconMargin: '3px 14px 0 0',
            //   searchIconMargin: '0 0 0 16px
          }}
        />
      </div>
    </div>
  );
};

export default ItemInput;
