import React, { useContext, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { useTranslation } from "react-i18next";
import { UserContext } from "../context/UserContext";

export interface HeaderLinkItemProps {
  name: string;
  href: string;
}

const HeaderLinkItem = (props: HeaderLinkItemProps) => {
  return (
    <>
      <a
        key={props.name}
        href={props.href}
        className="text-sm font-semibold leading-6 text-sky-500 hover:text-gray-100 uppercase"
      >
        {props.name}
      </a>
      <span className="text-sky-500 hover:text-gray-100 mx-4">/</span>
    </>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigationPub = [{ name: "Home", href: "/" }];
  const navigationAuth = [{ name: "Lists", href: "/lists" }];

  return (
    <header className="">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-screen items-center justify-between p-6 lg:px-8 bg-slate-950"
      >
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">10 Goats</span>
            <img alt="10 Goats" src="/img/logo.png" className="h-8 w-auto" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-indigo-300"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12"></div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {navigationPub.map((item, index) => (
            <HeaderLinkItem key={index} name={item.name} href={item.href} />
          ))}
          {user.isloggedin ? (
            <>
              {navigationAuth.map((item, index) => (
                <HeaderLinkItem key={index} name={item.name} href={item.href} />
              ))}
              <a
                href={`/u/${user.display_name}`}
                className="text-sm font-semibold leading-6 text-sky-500 hover:text-gray-100 uppercase"
              >
                {t("login.profile")}
              </a>
              <span className="text-sky-500 hover:text-gray-100 mx-4">/</span>
              <a
                href="/logout"
                className="text-sm font-semibold leading-6 text-sky-500 hover:text-gray-100 uppercase"
              >
                {t("login.logout")}
              </a>
            </>
          ) : (
            <>
              <a
                href="/login"
                className="text-sm font-semibold leading-6 text-sky-500 hover:text-gray-100 uppercase"
              >
                {t("login.title")}
              </a>
              <span className="text-sky-500 hover:text-gray-100 mx-4">/</span>
              <a
                href="/signup"
                className="text-sm font-semibold leading-6 text-sky-500 hover:text-gray-100 uppercase"
              >
                {t("login.signup")}
              </a>
            </>
          )}
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">10GOATS</span>
              <img alt="10GOATS" src="/img/logo.png" className="h-8 w-auto" />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-400"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6"></div>
              <div className="py-6">
                {navigationPub.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-sky-500 hover:text-gray-100"
                  >
                    {item.name}
                  </a>
                ))}
                {user.isloggedin ? (
                  <>
                    {navigationAuth.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-sky-500 hover:text-gray-100"
                      >
                        {item.name}
                      </a>
                    ))}
                    <a
                      href={`/u/${user.display_name}`}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-sky-500 hover:text-gray-100"
                    >
                      {t("login.profile")}
                    </a>
                    <a
                      href="/logout"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-sky-500 hover:text-gray-100"
                    >
                      {t("login.logout")}
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href="/login"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-sky-500 hover:text-gray-100"
                    >
                      {t("login.title")}
                    </a>

                    <a
                      href="/signup"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-sky-500 hover:text-gray-100"
                    >
                      {t("login.signup")}
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
