import React from "react";

//import { useTranslation } from "react-i18next";

const Footer = () => {
  //const { t } = useTranslation();

  return (
    <div>
      <footer className=" text-white text-center py-4 mt-20">
        <div className="container mx-auto">
          <p className="text-xs">
            Created by{" "}
            <a href="https://twitter.com/okkhary" target="_blank">
              @okkhary
            </a>{" "}
            with ❤️ for Hip-Hop
          </p>
          <p className="text-xs">&copy; 2024 10 GOATS</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
