import React from "react";

export interface CategoryItemProps {
  id: string;
  name: string;
  description: string;
}
const CategoryItem = (props: CategoryItemProps) => {
  return (
    <div className="p-4 border-blue-charcoal-800 border rounded-sm">
      <h1 className="uppercase font-bold">GOAT {props.name}</h1>
      <p>{props.description}</p>
      <div className="inline-block">
        <a
          href={`/lists/create/${props.id}`}
          className="mt-4 text-sm mr-5 text-sky-500 hover:text-sky-300"
        >
          Create Your GOATs
        </a>
      </div>
      <div className="inline-block">
        <a
          href={`/ranks/${props.id}`}
          className="mt-4 text-sm mr-5 text-sky-500 hover:text-sky-300"
        >
          View Rankings
        </a>
      </div>
    </div>
  );
};

export default CategoryItem;
