import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import AdSense from "react-adsense";
import PageLoading from "../../components/PageLoading";
import GoatListAddEdit from "../../components/CreateList";
import { useParams } from "react-router-dom";
import { GetListByIdUser } from "../../api-requests/Get";
import { UserContext } from "../../context/UserContext";
import GoatListItem from "../../components/GoatListItem";
import Footer from "../../components/Footer";

const CreateList = () => {
  const { user } = useContext(UserContext);
  const { category_id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [myListData, setMyListData] = useState<any>(null);

  useEffect(() => {
    const asyncGet = async () => {
      console.log(category_id);
      const mylist = await GetListByIdUser(user.id, category_id);
      console.log("My List", mylist);
      if (mylist.length > 0) {
        setMyListData(mylist);
      }
      setIsLoaded(true);
    };
    asyncGet();
  }, []);

  if (!isLoaded) {
    return <PageLoading />;
  }

  return (
    <>
      <Header />

      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 mt-8 mb-20">
        <div className="flex justify-center my-4">
          <AdSense.Google
            client="ca-pub-993288253482590"
            slot="5582515344"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
        </div>
        <div className="mb-2">
          {myListData ? (
            <>
              <h2 className="text-xl md:text-2xl font-extrabold uppercase mb-4">
                Your Created List
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {myListData !== null &&
                  myListData[0].data.UserListItems.map(
                    (rank: any, index: number) => {
                      return (
                        <GoatListItem
                          key={index}
                          id={rank.Item.id}
                          category_label={rank.Item.name}
                          category_id={rank.Item.categoryId}
                          image={rank.Item.imagePath}
                          index={index}
                          total_points={rank.Item.points}
                          average_rank={rank.Item.avg_rank}
                          number_of_lists={rank.Item.num_lists}
                        />
                      );
                    }
                  )}
              </div>
            </>
          ) : (
            <GoatListAddEdit categoryId={category_id} mode="add" />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateList;
