export const SaveToLocalStorage = (
  key: string,
  data: string | number | null
) => {
  if (typeof window !== "undefined" && window.localStorage) {
    if (typeof data !== "string") {
    }
    const storageData = typeof data === "string" ? data : JSON.stringify(data);
    localStorage.setItem(key, storageData);
  } else {
    alert("Local storage is not supported in this browser.");
  }
};

export const GetFromLocalStorage = (key: string): string | null => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedData = localStorage.getItem(key);
    if (storedData) {
      return storedData;
    }
  }
  return null;
};

export const DeleteFromLocalStorage = (key: string) => {
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.removeItem(key);
  }
};

export const ClearLocalStorage = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.clear();
  }
};
