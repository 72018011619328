import React, { useEffect } from "react";
import { formatNumber } from "../helpers/NumberFormats";

export interface GoatListItemProps {
  category_label: string;
  category_id: string;
  id: string;
  index: number;
  total_points?: number | string;
  average_rank?: number | string;
  number_of_lists?: number | string;
  image?: string;
  highlightFirst?: boolean;
  hash?: string;
}

const GoatListItem = (props: GoatListItemProps) => {
  const [bgColor, setBgColor] = React.useState("bg-transparent");
  useEffect(() => {
    if (props.index === 0 && props.highlightFirst !== false) {
      setBgColor("bg-green-950");
    }
  }, [props.index]);

  useEffect(() => {
    if (props.hash === `#${props.id}`) {
      setBgColor("bg-green-950");
    } else {
      if (props.index !== 0 && !props.highlightFirst) {
        setBgColor("bg-transparent");
      }
    }
  }, [props.hash]);

  return (
    <li
      id={props.id}
      key={props.index}
      className={`flex gap-x-4 rounded-sm border p-3 border-slate-800 ${bgColor} transition-colors duration-500 hover:bg-blue-charcoal-400 hover:text-black`}
    >
      <div className="flex items-center flex-none justify-center w-14 h-14 rounded-full bg-sky-700 text-white text-xl">
        <a href={`/ranks/${props.category_id}/#${props.id}`}>
          <img
            alt={props.category_label}
            src={`/${props.image}`}
            className="object-cover w-14 h-14 border-2 ring-2 border-slate-800 ring-slate-800 "
          />
        </a>
      </div>
      <div className="min-w-0">
        <p className="text-sm font-semibold leading-6">
          <span className="font-extrabold text-2xl">#{props.index + 1}</span>{" "}
          <a href={`/ranks/${props.category_id}/#${props.id}`}>
            {props.category_label}
          </a>
        </p>
        <p className="mt-0.5 truncate text-xs leading-5 text-gray-500 hover:text-black">
          {`Points: ${formatNumber(props.total_points)} | `}
          {`Avg Rank: ${props.average_rank}`}
          {/* {`${formatNumber(props.number_of_lists)} Lists`} */}
        </p>
      </div>
    </li>
  );
};

export default GoatListItem;
