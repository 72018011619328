import React, { useEffect, useState } from "react";
import GoatListItem from "../../components/GoatListItem";
import { GetUsersListById } from "../../api-requests/Get";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { generations } from "../../constants/constants";
import UsersHeader from "../../components/UsersHeader";
//import { Helmet } from "react-helmet-async";
import PageLoading from "../../components/PageLoading";
import Footer from "../../components/Footer";

const UsersList = () => {
  const { listid } = useParams();
  const [listData, setListData] = useState(null);

  useEffect(() => {
    const asyncGet = async () => {
      const listResults = await GetUsersListById(listid);

      const userProps = {
        ...listResults.user,
        avatar: listResults?.list[0].imagePath,
      };
      const user = {
        ...listResults,
        user: userProps,
      };
      //console.log(user);
      setListData(user);
    };
    asyncGet();
  }, []);

  if (!listData) {
    return <PageLoading />;
  }

  return (
    <>
      {/* <Helmet>
        <title>10 GOAT Emcees of {`${listData?.user.display_name}`}</title>
        <meta
          property="og:title"
          content={`10 GOATs Of ${listData?.user.display_name}`}
        />
        <meta
          property="og:description"
          content={`Here's ${listData?.user.display_name}'s 10 GOATs, Curate your ultimate top 10 emcee lists at 10goats.com! `}
        />
        <meta
          property="og:image"
          content="https://10goats.com/ogimage.jpg?v=4"
        />
        <meta property="og:url" content={`https://10goats.com/l/${listid}`} />
        <meta
          name="description"
          content={`Here's ${listData?.user.display_name}'s 10 GOATs, Curate your ultimate top 10 emcee lists at 10goats.com! `}
        />
      </Helmet> */}
      <Header />
      <UsersHeader profile={listData?.user} header_size="sm" />

      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mb-2">
          <p className="mt-4 ">
            <span className="text-xs font-bold uppercase">
              {generations[listData?.user.generation - 1]}
            </span>
            <br />
            <span className="text-xl md:text-2xl font-extrabold">
              10 Goats Of{" "}
              <a
                href={`/u/${listData?.user.display_name}`}
                className="text-sky-500"
              >
                {listData?.user.display_name}
              </a>{" "}
            </span>
          </p>
          <p className="text-sm my-4">{listData?.list[0].details}</p>
        </div>
        <ul role="list" className=" grid grid-cols-1 md:grid-cols-2 gap-3">
          {listData?.list.map((item, index) => {
            return (
              <GoatListItem
                key={index}
                id={item.item_id}
                category_label={item.item_name}
                category_id={item.categoryId}
                image={item.imagePath}
                index={index}
                total_points={item.points}
                average_rank={item.avg_rank}
                number_of_lists={item.num_lists}
              />
            );
          })}
        </ul>
        <div className="mt-14">
          <div className="sharethis-inline-reaction-buttons"></div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UsersList;
