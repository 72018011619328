import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  XIcon,
  LinkedinIcon,
} from "react-share";

const ShareButtons = ({ url, text, twitter_url }) => {
  return (
    <div className="flex gap-2">
      <TwitterShareButton url={twitter_url} title={text}>
        <XIcon size={32} />
      </TwitterShareButton>

      <FacebookShareButton url={url}>
        <FacebookIcon size={32} />
      </FacebookShareButton>

      <LinkedinShareButton url={url}>
        <LinkedinIcon size={32} />
      </LinkedinShareButton>
    </div>
  );
};

export default ShareButtons;
