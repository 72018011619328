import React from "react";
import GoatListItem from "./GoatListItem";
import { generations } from "../constants/constants";

export interface UserListProps {
  listdata: any;
}
const UsersList = (props: UserListProps) => {
  return (
    <div className="">
      <div className="mb-2">
        <p className="mt-4 ">
          <span className="text-xs font-bold uppercase text-gray-400">
            {generations[props.listdata.user.generation - 1]}
          </span>
          <br />
          <span className="font-extrabold uppercase">
            10{" "}
            <a
              href={`/l/${props.listdata?.lists[0].id}`}
              className="text-sky-500 hover:text-gray-100"
            >
              Goats
            </a>{" "}
            Of{" "}
            <a
              href={`/u/${props.listdata.user.display_name}`}
              className="text-sky-500 hover:text-gray-100"
            >
              {props.listdata.user.display_name}
            </a>{" "}
          </span>
        </p>
      </div>
      <ul role="list" className=" grid grid-cols-1 md:grid-cols-2 gap-3">
        {props.listdata?.lists[0].UserListItems.map((item, index) => {
          return (
            <GoatListItem
              key={index}
              id={item.Item?.id}
              category_label={item.Item?.name}
              category_id={props.listdata?.lists[0].categoryId}
              image={item.Item?.imagePath}
              index={index}
              total_points={item.Item?.points}
              average_rank={item.Item?.avg_rank}
              number_of_lists={item.Item?.num_lists}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default UsersList;
