import React, { ChangeEvent, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

const RequestPassword = () => {
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");

  const submitRequest = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .post("/api/reset-request", {
        username: email,
      })
      .then((response) => {
        if (response.data.success) {
          setMsg(
            "If the email you provided matches an account you will receive an email with instructions."
          );
        } else {
          setMsg(response.data.message);
        }
      });
  };

  return (
    <>
      <div className="flex h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 m-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img
              className="mx-auto h-10 w-auto"
              src="/img/logo.png"
              alt="10GOATS"
            />
          </Link>
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Confirm your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {msg && (
              <>
                <div className="border-2 border-red p-4 bg-gray-200 text-black text-sm mb-5 rounded-md">
                  {msg}
                </div>
              </>
            )}
            <form className="space-y-6" onSubmit={submitRequest}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email"
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    required
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Request Reset Link
                </button>
              </div>
            </form>
          </div>
        </div>

        <p className="mt-10 text-center text-sm text-white">
          <Link
            className="font-semibold text-sky-500 hover:text-gray-100"
            to="/login"
          >
            Login
          </Link>{" "}
          Or{" "}
          <Link
            className="font-semibold text-sky-500 hover:text-gray-100"
            to="/signup"
          >
            Create an account
          </Link>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default RequestPassword;
